import BranchBoy from './BranchBoy.jpeg';
import Logo from './Logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <img src={Logo} className="logo" alt="logo" />

      <header className="App-header">
        <div className="presenting">
          <h1 style={{
            fontSize: "calc(20px + 5vmin)",
            display: "flex",
            flexDirection: "column",
            marginTop: "unset"
          }}>
            <span>
              Branch Boy
            </span>
            <span style={{fontSize: "calc(2px + 3vmin)", fontWeight: 500}}>
              Tree pruning & removal services
            </span>
          </h1>

          <img src={BranchBoy} className="App-logo" alt="boy cutting branches"/>

          <p style={{fontSize: "calc(3px + 3vmin)", display: "flex", flexDirection: "column"}}>
            <span>
              If you're in the Springfield Missouri area,&nbsp;
            </span>
            <span>
              contact Branch Boy for a quote!
            </span>
          </p>

          <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            fontSize: "calc(6px + 2vmin)"
          }}>
            <span>
              Phone number:&nbsp;
              <a
                  className="App-link"
                  href="tel:8018703672"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                801-870-3672
              </a>
            </span>
            <span>
              Email address:&nbsp;&nbsp;
              <a
                  className="App-link"
                  href="mailto:mason.eyre@icloud.com"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                mason.eyre@icloud.com
              </a>
            </span>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
